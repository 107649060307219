import React from "react";
import PropTypes from "prop-types";
import "./field.scss";

const Field = ({ label, children }) => {
  return (
    <div className="field">
      <div className="field-label">{label}</div>
      {children}
    </div>
  );
};

Field.propTypes = { children: PropTypes.node, label: PropTypes.string };

export default Field;
