import { useState } from "react";

const useModal = (initialStatus = false) => {
  const [showModal, setShowModal] = useState(initialStatus);

  function toggle() {
    setShowModal(!showModal);
  }

  return {
    showModal,
    setShowModal,
    toggle,
  };
};

export default useModal;
