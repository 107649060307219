import React from "react";
import PropTypes from "prop-types";
import { useFieldFocus } from "../../hooks/signature-focus-context";
import { useFormikContext } from "formik";
import { scrollToElement } from "../../utils";
import "./checkbox-input.scss";

const CheckBoxInput = ({ id, pos, size, attributes }) => {
  const { isFocused, focusElement } = useFieldFocus({
    id,
    attributes
  });
  const { values, setFieldValue } = useFormikContext();
  const elemRef = React.useRef();
  React.useEffect(() => {
    if (isFocused && elemRef && elemRef.current) {
      scrollToElement(elemRef.current);
    }
  }, [isFocused]);
  return (
    <div
      className={`preview-item checkbox-element ${isFocused ? "focused" : ""}`}
      style={{
        width: size.absoluteWidth,
        height: size.absoluteHeight,
        margin: `${size.absoluteTop + 35} 0 0 ${size.absoluteLeft}`
      }}
      onClick={() => focusElement(id)}
    >
      <div
        style={{
          width: size.absoluteWidth,
          height: size.absoluteHeight,
          margin: `${size.absoluteTop} 0 0 ${size.absoluteLeft}`
        }}
        className="no-box"
      >
        <div className="checkbox-input-containter">
          <input
            className="checkbox-input"
            type="checkbox"
            ref={elemRef}
            id={id}
            checked={values[id]}
            onChange={({ target }) => setFieldValue(id, target.checked)}
          />
          <span className="checkbox-input-label">{attributes.label}</span>
        </div>
      </div>
    </div>
  );
};

CheckBoxInput.propTypes = {
  id: PropTypes.string.isRequired
};

export default CheckBoxInput;
