import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectLayout } from "@redux/layout";
import { selectTemplateInfo } from "@redux/template-info";
import FormItem from "@components/form-item";
import "./style.scss";

const TemplateWorkspace = React.forwardRef(
  ({ onDragOver, onDragLeave, active, token }, dropZoneRef) => {
    const layout = useSelector(selectLayout);
    const templateInfo = useSelector(selectTemplateInfo);
    const currentImgUrl = `api/template/download/${templateInfo.template_id}/${
      templateInfo.files[templateInfo.currentPage]
    }?token=${token}&create_template=true`;
    return (
      <div
        className="form-layout droppable"
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
      >
        <img
          src={currentImgUrl}
          id="formbuilder-document"
          alt="document"
          draggable="false"
          onDragStart={e => {
            // NOTE: disable image dragging on firefox
            e.preventDefault();
            return false;
          }}
          className={`form-layout-img ${active ? "active" : ""}`}
          style={{
            userSelect: "none",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 0,
            width: 916
          }}
          ref={dropZoneRef}
        />
        {layout
          .filter(x => x.page === templateInfo.currentPage)
          .map((item, index) => (
            <FormItem key={item.id} {...item} index={index} />
          ))}
      </div>
    );
  }
);

TemplateWorkspace.propTypes = {
  onDragOver: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired
};

export default TemplateWorkspace;
