import React from "react";
import PropTypes from "prop-types";
import {
  TextInput,
  Label,
  SignatureInput,
  InitialsInput,
  RadioGroupInput,
  CheckBoxInput,
} from "../patient-elements";
import PatientSignature from "../patient-signature";
import PatientControls from "../patient-controls";
import SignatureFocusContext, {
  useSignatureFormFocus,
} from "../../hooks/signature-focus-context";
import { Formik } from "formik";
import generateValidationSchema from "../form-validation";
import Loading from "../ui/loading";
import { assoc } from "ramda";
import "./style.scss";

const Y_OFFSET = 35; // from edit controls in formbuilder

const SignDocumentScreen = ({
  layout,
  handleSave,
  dmePreview,
  printView,
  templateInfo,
  initialValues,
  coachSignature,
  signedByCoach
}) => {
  const content = React.useRef();
  const [zoom, setZoom] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(0);
  const imgUrl = `/api/template/download/${templateInfo.template_id}/${templateInfo.files[currentPage]}?token=${templateInfo.token}`;
  const {
    focusElement,
    focusNextElement,
    focusPrevElement,
    formElements,
    focusedEl,
  } = useSignatureFormFocus(layout);
  const [imgLoaded, setImgLoaded] = React.useState(false);
  // const [autoSelectFirst, setAutoSelectFirst] = React.useState(false);
  // React.useEffect(() => {
  //   if (formElements.length > 0 && !autoSelectFirst) {
  //     setAutoSelectFirst(true);
  //     focusElement(formElements[0].id);
  //   }
  // }, [formElements, focusElement, autoSelectFirst]); /* eslint-disable-line */
  return (
    <SignatureFocusContext.Provider
      value={{
        focusElement,
        focusNextElement,
        pageCount: templateInfo.files.length,
        currentPage,
        setCurrentPage,
        focusPrevElement,
        focusedEl,
        formElements,
      }}
    >
      <Formik
        initialValues={
          initialValues
            ? initialValues
            : layout
                .filter(({ type }) => type !== "LABEL")
                .reduce((acc, { id }) => assoc(id, "", acc), {
                  signature: "",
                  patient_name: "",
                  signed_date: "",
                  patient_initials: "",
                  font: "Sofia",
                })
        }
        validationSchema={!coachSignature && generateValidationSchema(layout)}
        onSubmit={(values) => {
          handleSave(values);
        }}
      >
        {({ values }) => (
          <React.Fragment>
            {!printView && !dmePreview && imgLoaded && <PatientSignature />}
            {!imgLoaded && <Loading />}
            <div
              id="form-builder-preview"
              className={`form-builder-preview ${
                printView ? "print-view" : ""
              }`}
              ref={content}
              style={{
                transform: `scale(${zoom})`,
                height: "100vh",
                visibility: imgLoaded ? "visible" : "hidden",
              }}
            >
              <img
                src={imgUrl}
                onLoad={() => setImgLoaded(true)}
                alt="document"
                draggable="false"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: 0,
                  width: 916,
                  userSelect: "none",
                }}
                onClick={() => {
                  focusElement(false);
                }}
              />
              {layout
                .filter((x) => x.page === currentPage)
                .map(({ id, type, pos, size, attributes, value }) => (
                  <div
                    key={id}
                    style={{
                      position: "absolute",
                      top: pos.y + Y_OFFSET,
                      left: pos.x,
                    }}
                  >
                    {type === "TEXT" && !coachSignature && (
                      <TextInput
                        id={id}
                        pos={pos}
                        size={size}
                        attributes={attributes}
                        printView={printView}
                      />
                    )}
                    {type === "LABEL" && (
                      <Label id={id} pos={pos} attributes={attributes} />
                    )}
                    {(type === "SIGNATURE" && !coachSignature) && (
                      <SignatureInput
                        id={id}
                        pos={pos}
                        size={size}
                        attributes={attributes}
                        signatureData={values.signature}
                        triggerSignature={""}
                      />
                    )}
                    {(type === "COACH_SIGNATURE" && (dmePreview || signedByCoach || coachSignature)) && (
                      <SignatureInput
                        id={id}
                        pos={pos}
                        size={size}
                        attributes={attributes}
                        signatureData={values.signature}
                        triggerSignature={(!coachSignature && signedByCoach) ? value : values.signature}
                        coachIsSigning={coachSignature}
                      />
                    )}
                    {type === "INITIALS" && !coachSignature && (
                      <InitialsInput
                        id={id}
                        pos={pos}
                        size={size}
                        initials={values.patient_initials}
                        attributes={attributes}
                      />
                    )}
                    {type === "DATE" && !coachSignature && (
                      <TextInput
                        type="date"
                        id={id}
                        pos={pos}
                        size={size}
                        attributes={attributes}
                      />
                    )}
                    {type === "CHECKBOX" && !coachSignature && (
                      <CheckBoxInput
                        id={id}
                        pos={pos}
                        size={size}
                        attributes={attributes}
                      />
                    )}
                    {type === "RADIO_GROUP" && !coachSignature && (
                      <RadioGroupInput
                        id={id}
                        pos={pos}
                        size={size}
                        attributes={attributes}
                      />
                    )}
                  </div>
                ))}
            </div>
            {!printView && imgLoaded && (
              <PatientControls
                setZoom={setZoom}
                zoom={zoom}
                dmePreview={dmePreview}
              />
            )}
          </React.Fragment>
        )}
      </Formik>
    </SignatureFocusContext.Provider>
  );
};

export default SignDocumentScreen;

SignDocumentScreen.propTypes = {
  layout: PropTypes.arrayOf(PropTypes.object).isRequired,
  templateInfo: PropTypes.object.isRequired,
  handleSave: PropTypes.func,
  dmePreview: PropTypes.bool,
  printView: PropTypes.bool,
};
