import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { deleteFormItem, updateFormItemAttributes } from "../../redux/layout";
import Field from "../ui/field";
import Input from "../ui/input";
import Controls from "./controls";
import useResize from "../../hooks/use-resize";
import { Resizable } from "react-resizable";
import useHover from "../../hooks/use-hover";

const InitialsElement = ({ id, pos, size: origSize, attributes }) => {
  const dispatch = useDispatch();
  const [elemRef, isHovered] = useHover();
  const { size, onResize, onResizeStop } = useResize(id, origSize);
  return (
    <div
      className="layout-item initials-element"
      ref={elemRef}
      style={{
        borderBottom: attributes.underline ? "1px solid #333" : "none"
      }}
    >
      <Controls
        handleDelete={() => {
          dispatch(deleteFormItem({ id }));
        }}
        showControls={isHovered}
        EditComponent={
          <div
            className="edit-element-container"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Field label="Required">
              <Input
                type="checkbox"
                label="Required"
                checked={attributes.required}
                onChange={({ target }) =>
                  dispatch(
                    updateFormItemAttributes({
                      id,
                      attributes: {
                        ...attributes,
                        required: target.checked
                      }
                    })
                  )
                }
              />
            </Field>
            {/* <Field label="Underline"> */}
            {/*   <Input */}
            {/*     checked={attributes.underline} */}
            {/*     type="checkbox" */}
            {/*     onChange={({ target }) => */}
            {/*       dispatch( */}
            {/*         updateFormItemAttributes({ */}
            {/*           id, */}
            {/*           attributes: { */}
            {/*             ...attributes, */}
            {/*             underline: target.checked, */}
            {/*           }, */}
            {/*         }) */}
            {/*       ) */}
            {/*     } */}
            {/*   /> */}
            {/* </Field> */}
          </div>
        }
      >
        <Resizable
          className={`signature-input absolutely-positioned ${
            attributes.underline ? "underlined" : ""
          }`}
          height={size.absoluteHeight}
          width={size.absoluteWidth}
          minConstraints={[80, 20]}
          maxConstraints={[800, 400]}
          onResize={onResize}
          onResizeStop={onResizeStop}
          resizeHandles={["se"]}
          lockAspectRatio
        >
          <div
            style={{
              width: size.absoluteWidth,
              height: size.absoluteHeight,
              margin: `${size.absoluteTop} 0 0 ${size.absoluteLeft}`
            }}
          >
            <div className="handle">
              <div className="button primary">
                <span>Initials</span>
              </div>
            </div>
          </div>
        </Resizable>
      </Controls>
    </div>
  );
};

InitialsElement.propTypes = {
  id: PropTypes.string.isRequired
};

export default InitialsElement;
