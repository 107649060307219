import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { deleteFormItem, updateFormItemAttributes } from "../../redux/layout";
import Field from "../ui/field";
import Controls from "./controls";
import useHover from "../../hooks/use-hover";

const LabelElement = ({ id, pos, attributes }) => {
  const dispatch = useDispatch();
  const [width, setWidth] = React.useState(0);
  const inputShadowRef = React.useRef();
  const [elemRef, isHovered] = useHover();
  React.useEffect(() => {
    if (inputShadowRef && inputShadowRef.current) {
      setWidth(inputShadowRef.current.offsetWidth);
    }
  }, [attributes.value, attributes.fontSize, attributes.color]);
  return (
    <div className="layout-item label-element" ref={elemRef}>
      <Controls
        handleDelete={() => {
          dispatch(deleteFormItem({ id }));
        }}
        showControls={isHovered}
        EditComponent={
          <div>
            <Field label="Color">
              <input
                type="color"
                value={attributes.color}
                onChange={({ target }) =>
                  dispatch(
                    updateFormItemAttributes({
                      id,
                      attributes: {
                        ...attributes,
                        color: target.value,
                        fontSize: attributes.fontSize,
                      },
                    })
                  )
                }
              />
            </Field>
            <Field label="Font Size">
              <input
                type="range"
                value={attributes.fontSize}
                min="1"
                max="100"
                onChange={({ target }) => {
                  dispatch(
                    updateFormItemAttributes({
                      id,
                      attributes: {
                        value: attributes.value,
                        color: attributes.color,
                        fontSize: target.value,
                      },
                    })
                  );
                  var theWidth = inputShadowRef.current.getBoundingClientRect()
                    .width;
                  setWidth(theWidth);
                }}
              />
              <div style={{ paddingLeft: 5 }}>{attributes.fontSize}px</div>
            </Field>
          </div>
        }
      >
        <input
          className="label-input handle"
          value={attributes.value}
          onChange={({ target }) => {
            dispatch(
              updateFormItemAttributes({
                id,
                attributes: { ...attributes, value: target.value },
              })
            );
            var theWidth = inputShadowRef.current.getBoundingClientRect().width;
            setWidth(theWidth);
          }}
          style={{
            fontSize: `${attributes.fontSize}px`,
            color: attributes.color,
            width: `${width + 30}px`,
          }}
        />
        <div
          className="label-input-shadow"
          ref={inputShadowRef}
          style={{
            fontSize: `${attributes.fontSize}px`,
            color: attributes.color,
          }}
        >
          {attributes.value}
        </div>
      </Controls>
    </div>
  );
};

LabelElement.propTypes = {
  id: PropTypes.string.isRequired,
};

export default LabelElement;
