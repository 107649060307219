import React from "react";
import PropTypes from "prop-types";
import "./input.scss";

const Input = ({ className, ...props }) => (
  <input className={`input ${className}`} {...props} />
);

Input.defaultProps = { className: "" };

Input.propTypes = {
  className: PropTypes.string
};

export default Input;
