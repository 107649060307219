import React from "react";
import NotFoundImage from "../../assets/images/404-error.png";

const NotFound = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        class="error-img"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: 300,
          width: 300,
        }}
      >
        <img
          className="img-fluid"
          src={NotFoundImage}
          alt="404 Error"
          style={{ maxWidth: "100%" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <h3>
          You are not authorized to access this page.
          <br />
          Please try again or contact an administrator
        </h3>
        <p style={{ textAlign: "center" }}>
          <h3>Please close this window.</h3>
        </p>
      </div>
    </div>
  );
};

export default NotFound;
