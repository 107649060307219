import React from "react";
import { Link } from "react-router-dom";

const Development = () => {
  const [inputs, setInputs] = React.useState({
    token: "",
    template_id: "",
    signature_id: "",
  });
  const createTemplateLink = `/create-template?token=${window.btoa(
    inputs.token
  )}`;
  const editTemplateLink = `/template-editor?token=${window.btoa(
    inputs.token
  )}&template_id=${inputs.template_id}`;
  const signSignatureLink = `/signature&token=${window.btoa(
    inputs.token
  )}&template_id=${inputs.template_id}&signature_id=${inputs.signature_id}`;
  return (
    <div style={{ padding: 5, overflow: "auto", height: "100vh" }}>
      <h2>Development/Testing</h2>
      <div
        style={{
          display: "grid",
          padding: 10,
          width: 600,
          gridTemplateColumns: "auto auto",
          alignItems: "center",
          gridGap: 10,
        }}
      >
        <div>
          <label>Token</label>
          <div>* dme token = phoenix token, patient = signature token</div>
        </div>
        <input
          type="text"
          value={inputs.token}
          onChange={({ target }) =>
            setInputs((x) => ({ ...x, token: target.value }))
          }
        />
        <label>Template Id</label>
        <input
          type="text"
          value={inputs.template_id}
          onChange={({ target }) =>
            setInputs((x) => ({ ...x, template_id: target.value }))
          }
        />
        <label>Signature Id</label>
        <input
          type="text"
          value={inputs.signature_id}
          onChange={({ target }) =>
            setInputs((x) => ({ ...x, signature_id: target.value }))
          }
        />
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ padding: 10 }}>
          <Link to={createTemplateLink}>Create Template</Link>
          <div>Link: {createTemplateLink}</div>
        </div>
        <div style={{ padding: 10 }}>
          <Link to={editTemplateLink}>Edit Template</Link>
          <div>Link: {editTemplateLink}</div>
        </div>
        <div style={{ padding: 10 }}>
          <Link to="/formbuilder">Sign document</Link>
          <div>Link: {signSignatureLink} </div>
        </div>
        <div style={{ padding: 10 }}>
          <Link to="/formbuilder">Print preview document</Link>
          <div>Link: </div>
        </div>
      </div>
    </div>
  );
};

Development.defaultProps = {};

Development.propTypes = {};

export default Development;
