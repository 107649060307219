import React from "react";
import PropTypes from "prop-types";
import { Button, Icon } from "semantic-ui-react";
import useModal from "../../hooks/use-modal";
import Modal from "../ui/modal";

const ElementControls = ({
  handleDelete,
  children,
  isRequired,
  EditComponent,
  showControls,
}) => {
  const { showModal, toggle } = useModal(false);
  return (
    <React.Fragment>
      {children}
      <Modal isShowing={showModal}>
        {EditComponent}
        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            padding: 10,
          }}
        >
          <button className="button primary" onClick={toggle}>
            Close
          </button>
        </div>
      </Modal>
      <div
        style={{
          display: showControls ? "inline-block" : "none",
          position: "absolute",
          zIndex: 5,
          right: 0,
          top: 5,
          border: "none",
          height: 30
        }}
        className="element-controls"
      >
        {isRequired && <span style={{ marginRight: 5 }}>*</span>}
        <Button.Group size="mini">
          <Button icon primary onClick={toggle} size="mini">
            <Icon name="edit" size="small" />
          </Button>
          <Button icon negative onClick={handleDelete} size="mini">
            <Icon name="trash" size="small" />
          </Button>
        </Button.Group>
      </div>
    </React.Fragment>
  );
};
export default ElementControls;

ElementControls.propTypes = {
  handleDelete: PropTypes.func,
  children: PropTypes.node,
  EditComponent: PropTypes.node,
  isRequired: PropTypes.bool,
};
