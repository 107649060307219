import React from "react";

const NotAuthorized = () => {
  return (
    <div
      style={{
        padding: 10,
        display: "flex",
        justifyItems: "center",
        alignItems: "center",
        flex: 1,
        maxWidth: "90vw",
        maxHeight: "90vh",
        height: "90vh",
        flexDirection: "column",
      }}
    >
      <img
        src="https://c-papyrus.com/public/front/images/404-error.png"
        style={{ maxWidth: "100%", marginBottom: 20 }}
        alt="404"
      />
      <h2 style={{ textAlign: "center" }}>
        You are not authorized to access this page. Please try again or contact
        an administrator
      </h2>
      <h3 style={{ textAlign: "center" }}>Please close this window.</h3>
    </div>
  );
};

NotAuthorized.defaultProps = {};

NotAuthorized.propTypes = {};

export default NotAuthorized;
