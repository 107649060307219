import * as Yup from "yup";
import { assoc, propEq } from "ramda";

const generateValidationSchema = layout => {
  const layoutValidation = layout
    .filter(
      ({ type, attributes }) =>
        type !== "LABEL" && propEq("required", true, attributes)
    )
    .reduce(
      (acc, { id, type }) =>
        assoc(id, Yup.string().required(getErrorMsg(type)), acc),
      {}
    );
  return Yup.object().shape({
    patient_name: Yup.string()
      .min(2, "Name Is Required")
      .max(200, "Too many characters")
      .required("Required"),
    patient_initials: Yup.string()
      .max(5, "Too many characters")
      .required("Required"),
    signature: Yup.string().required("Signature Required"),
    ...layoutValidation
  });
};

export default generateValidationSchema;

const getErrorMsg = type => {
  switch (type) {
    case "SIGNATURE":
      return "Signature is required";
    case "INITIALS":
      return "Your Initials are required";
    case "DATE":
      return "Date is required";
    case "TEXT":
      return "Field is required";
    default:
      return "Field is required";
  }
};
