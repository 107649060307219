import React from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import "./style.scss";

const Modal = ({ isShowing, hide, children, fullscreen }) =>
  isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className="modal-overlay" />
          <div
            className={`modal-wrapper ${fullscreen ? "fullscreen" : ""}`}
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
          >
            <div className="modal">
              {hide && (
                <div className="modal-header">
                  <button
                    type="button"
                    className="modal-close-button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={hide}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              )}
              <div className="model-content">{children}</div>
            </div>
          </div>
        </React.Fragment>,
        document.body
      )
    : null;

Modal.propTypes = {
  isShowing: PropTypes.bool,
  hide: PropTypes.func,
  children: PropTypes.node,
  fullscreen: PropTypes.bool,
};

export default Modal;
