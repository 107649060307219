import React from "react";
import "./style.scss";

const Loading = () => {
  return <div className="loading-spinner no-print">Loading</div>;
};

Loading.defaultProps = {};

Loading.propTypes = {};

export default Loading;
