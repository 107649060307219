import React from "react";
import { useDispatch } from "react-redux";
import { resizeFormItem } from "../redux/layout";

function useResize(id, initialSize) {
  const dispatch = useDispatch();
  const [size, setSize] = React.useState({
    absoluteHeight: initialSize.absoluteHeight,
    absoluteWidth: initialSize.absoluteWidth,
    absoluteTop: initialSize.absoluteTop,
    absoluteLeft: initialSize.absoluteLeft,
  });
  const getSize = (currentSize, elSize) => {
    const deltaHeight = elSize.height - currentSize.absoluteHeight;
    const deltaWidth = elSize.width - currentSize.absoluteWidth;
    return {
      absoluteTop: Math.floor(currentSize.absoluteTop + deltaHeight),
      absoluteLeft: Math.floor(currentSize.absoluteLeft + deltaWidth),
      absoluteWidth: Math.floor(elSize.width),
      absoluteHeight: Math.floor(elSize.height),
    };
  };

  const onResize = (event, { element, size: elSize, handle }) => {
    setSize({ ...getSize(size, elSize) });
  };

  const onResizeStop = (event, { element, size: elSize, handle }) => {
    const newSize = getSize(size, elSize);
    dispatch(resizeFormItem({ id, size: newSize }));
  };
  return { size, onResize, onResizeStop };
}

export default useResize;
