import React from "react";
import PropTypes from "prop-types";
import { useFieldFocus } from "../../hooks/signature-focus-context";
import { useFormikContext } from "formik";
import { scrollToElement } from "../../utils";
import "./radio-group-input.scss";

const RadioGroupInput = ({ id, pos, size, attributes }) => {
  const { isFocused, focusElement } = useFieldFocus({
    id,
    attributes
  });
  const elemRef = React.useRef();
  React.useEffect(() => {
    if (isFocused && elemRef && elemRef.current) {
      scrollToElement(elemRef.current);
    }
  }, [isFocused]);
  const { values, setFieldValue } = useFormikContext();
  return (
    <div
      className={`preview-item radio-group-element ${
        isFocused ? "focused" : ""
      }`}
      ref={elemRef}
      onClick={() => focusElement(id)}
    >
      <div
        style={{
          width: size.absoluteWidth,
          height: size.absoluteHeight,
          margin: `${size.absoluteTop} 0 0 ${size.absoluteLeft}`
        }}
      >
        <div className="radio-group">
          {attributes.elms?.map(({ value, text }) => (
            <div key={value} className="radio-group-item">
              <input
                id={value}
                type="radio"
                name={value}
                value={value}
                checked={values[id] === value}
                onChange={({ target }) => {
                  setFieldValue(id, target.name);
                }}
              />
              <label className="radio-item-label" htmlFor={value}>
                {text}
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

RadioGroupInput.propTypes = {
  id: PropTypes.string.isRequired
};

export default RadioGroupInput;
