import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { deleteFormItem, updateFormItemAttributes } from "../../redux/layout";
import Controls from "./controls";
import useHover from "../../hooks/use-hover";
import useResize from "../../hooks/use-resize";
import { Resizable } from "react-resizable";
import Field from "../ui/field";

const CheckBoxElement = ({ id, pos, size: origSize, attributes }) => {
  const dispatch = useDispatch();
  const [elemRef, isHovered] = useHover();
  const { size, onResize, onResizeStop } = useResize(id, origSize);
  return (
    <div className="layout-item checkbox-element" ref={elemRef}>
      <Controls
        handleDelete={() => {
          dispatch(deleteFormItem({ id }));
        }}
        showControls={isHovered}
        EditComponent={
          <div
            className="edit-element-container"
            style={{ display: "flex", flexDirection: "column" }}
          >
            {/* <Field label="label"> */}
            {/*   <input */}
            {/*     value={attributes.label} */}
            {/*     onChange={({ target }) => { */}
            {/*       dispatch( */}
            {/*         updateFormItemAttributes({ */}
            {/*           id, */}
            {/*           attributes: { ...attributes, label: target.value }, */}
            {/*         }) */}
            {/*       ); */}
            {/*     }} */}
            {/*     type="text" */}
            {/*   /> */}
            {/* </Field> */}
            <Field label="Required">
              <input
                type="checkbox"
                label="Required"
                checked={attributes.required}
                onChange={({ target }) =>
                  dispatch(
                    updateFormItemAttributes({
                      id,
                      attributes: {
                        ...attributes,
                        required: target.checked,
                      },
                    })
                  )
                }
              />
            </Field>
          </div>
        }
      >
        <Resizable
          className="absolutely-positioned"
          height={size.absoluteHeight}
          width={size.absoluteWidth}
          onResize={onResize}
          onResizeStop={onResizeStop}
          resizeHandles={["se"]}
          minConstraints={[50, 20]}
          maxConstraints={[200, 200]}
        >
          <div
            style={{
              width: size.absoluteWidth,
              height: size.absoluteHeight,
              margin: `${size.absoluteTop} 0 0 ${size.absoluteLeft}`,
            }}
          >
            <div className="checkbox-input-container handle">
              <input
                className="checkbox-input"
                type="checkbox"
                readOnly
                checked={false}
              />
              {/* <span className="checkbox-input-label">{attributes.label}</span> */}
            </div>
          </div>
        </Resizable>
      </Controls>
    </div>
  );
};

CheckBoxElement.propTypes = {
  id: PropTypes.string.isRequired,
};

export default CheckBoxElement;
