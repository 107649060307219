import React from "react";
import * as R from "ramda";
import { sortByPosition } from "../utils";

const SignatureFocusContext = React.createContext();

export default SignatureFocusContext;

export const useSignatureFormFocus = (layout = []) => {
  const formElements = R.pipe(
    sortByPosition,
    R.filter(({ type }) => type !== "LABEL"),
    elms => elms.map(({ id, pos }, index) => ({ id, pos, index }))
  )(layout);
  const indexedEls = R.indexBy(R.prop("id"), formElements);
  const [focusedEl, setFocusedEl] = React.useState({ index: -1, id: null });
  const focusNextElement = () => {
    const nextElm = formElements[focusedEl.index + 1];
    if (nextElm) setFocusedEl(formElements[focusedEl.index + 1]);
  };
  const focusPrevElement = () => {
    const nextElm = formElements[focusedEl.index - 1];
    if (nextElm) setFocusedEl(formElements[focusedEl.index - 1]);
  };
  const focusElement = id => {
    if (id !== false) {
      setFocusedEl({ id: null, index: focusedEl.index });
      const nextElement = R.prop(id, indexedEls);
      if (nextElement) {
        setFocusedEl(nextElement);
      }
    }
  };
  return {
    focusedEl,
    focusNextElement,
    focusPrevElement,
    focusElement,
    formElements
  };
};

export const useFieldFocus = ({ id }) => {
  const { focusedEl, focusElement } = React.useContext(SignatureFocusContext);
  return {
    focusElement,
    focusedEl,
    isFocused: focusedEl.id === id
  };
};
