import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import queryString from "query-string";
import useFormControlDrag from "@hooks/useFormControlDrag";
import FormControls from "./form-controls";
import TemplateWorkspace from "./template-workspace";
import { setTemplateInfo, selectTemplateInfo } from "@redux/template-info";
import { setLayout } from "@redux/layout";

const TemplateEditor = () => {
  const dropZoneRef = React.useRef();
  const {
    onDragStart,
    onDragEnd,
    onDragOver,
    onDragLeave,
    onDrop,
    active,
    selectedControl,
  } = useFormControlDrag(dropZoneRef);
  return (
    <div className={`App ${selectedControl}`}>
      <FormControls onDragStart={onDragStart} onDragEnd={onDragEnd} />
      <TemplateWorkspace
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
        onDrop={onDrop}
        ref={dropZoneRef}
        active={active}
      />
    </div>
  );
};

const TemplateLoader = () => {
  const error = false;
  const isLoading = false;
  const location = useLocation();
  const dispatch = useDispatch();
  const params = queryString.parse(location.search);
  const templateInfo = useSelector(selectTemplateInfo);

  useQuery(
    ["meta_layout", params],
    async () => {
      if (templateInfo.template_id === "") {
        const metaResponse = await fetch(
          `/api/template/download/${params.template_id}/meta.json?token=${params.token}`
        );
        const metaData = await metaResponse.json();
        dispatch(
          setTemplateInfo({
            ...params,
            ...metaData,
            loaded: true,
          })
        );
        const layoutResponse = await fetch(
          `/api/template/download/${params.template_id}/layout.json?token=${params.token}`
        );
        const layoutData = await layoutResponse.json();
        dispatch(setLayout(layoutData));
      }
    },
    { enabled: params !== undefined && Object.keys(params).length > 0 }
  );

  if (templateInfo.loaded) return <TemplateEditor />;
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        minHeight: 400,
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {isLoading && (
        <>
          <h3>Getting everything setup</h3>
        </>
      )}
      <div style={{ margin: 10, color: "red" }}>{error}</div>
    </div>
  );
};

export default TemplateLoader;
