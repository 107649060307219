import React from "react";
import FontFaceObserver from "fontfaceobserver";

var font1 = new FontFaceObserver("Sofia");
var font2 = new FontFaceObserver("Arizonia");
var font3 = new FontFaceObserver("Allura");

const useFontLoader = () => {
  const [fontsLoaded, setFontsLoaded] = React.useState(false);
  React.useEffect(() => {
    Promise.all([font1.loaded, font2.loaded, font3.loaded]).then(() => {
      setFontsLoaded(true);
    });
  }, []);
  return [fontsLoaded];
};
export default useFontLoader;
