import React from "react";
import PropTypes from "prop-types";
import {
  TextInput,
  Label,
  SignatureInput,
  InitialsInput,
  RadioGroupInput,
  CheckBoxInput,
} from "../patient-elements";
import SignatureFocusContext, {
  useSignatureFormFocus,
} from "../../hooks/signature-focus-context";
import { Formik } from "formik";
import generateValidationSchema from "../form-validation";
import Loading from "../ui/loading";
import { assoc } from "ramda";
import "./style.scss";

const Y_OFFSET = 35; // from edit controls in formbuilder

const PrintSignedDocumentScreen = ({ layout, templateInfo, initialValues }) => {
  const content = React.useRef();
  const [imgLoaded, setImgLoaded] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(0);
  const {
    focusElement,
    focusNextElement,
    focusPrevElement,
    formElements,
    focusedEl,
  } = useSignatureFormFocus(layout);
  return (
    <SignatureFocusContext.Provider
      value={{
        focusElement,
        focusNextElement,
        pageCount: templateInfo.files.length,
        currentPage,
        setCurrentPage,
        focusPrevElement,
        focusedEl,
        formElements,
      }}
    >
      <Formik
        enableReinitialize
        initialValues={
          initialValues
            ? initialValues
            : layout
                .filter(({ type }) => type !== "LABEL")
                .reduce((acc, { id }) => assoc(id, "", acc), {
                  signature: "",
                  patient_name: "",
                  signed_date: "",
                  patient_initials: "",
                  font: "Sofia",
                })
        }
        validationSchema={generateValidationSchema(layout)}
        onSubmit={(values) => {}}
      >
        {({ values, handleSubmit }) => (
          <React.Fragment>
            {!imgLoaded && <Loading />}
            <>
              <div className="signed-doc-print-container">
                {templateInfo.files.map((x, index) => (
                  <div
                    className={`form-builder-preview print-view`}
                    ref={content}
                    style={{
                      height: "100%",
                      position: "relative",
                      visibility: imgLoaded ? "visible" : "hidden",
                    }}
                  >
                    <img
                      src={`/api/template/download/${templateInfo.template_id}/${templateInfo.files[index]}?token=${templateInfo.token}`}
                      onLoad={() => setImgLoaded(true)}
                      alt="document"
                      draggable="false"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        zIndex: 0,
                        width: 916,
                        userSelect: "none",
                      }}
                    />

                    {layout
                      .filter((x) => x.page === index)
                      .map(({ id, type, pos, size, attributes, value }, index) => (
                        <div
                          key={id}
                          style={{
                            position: "absolute",
                            top: pos.y + Y_OFFSET,
                            left: pos.x,
                          }}
                        >
                          {type === "TEXT" && (
                            <TextInput
                              id={id}
                              pos={pos}
                              size={size}
                              attributes={attributes}
                              printView={true}
                            />
                          )}
                          {type === "LABEL" && (
                            <Label id={id} pos={pos} attributes={attributes} />
                          )}
                          {type === "SIGNATURE" && (
                            <SignatureInput
                              id={id}
                              pos={pos}
                              size={size}
                              attributes={attributes}
                              signatureData={values.signature}
                              triggerSignature={""}
                            />
                          )}
                          {type === "COACH_SIGNATURE" && (
                            <SignatureInput
                              id={id}
                              pos={pos}
                              size={size}
                              attributes={attributes}
                              signatureData={values.signature}
                              triggerSignature={""}
                            />
                          )}
                          {type === "INITIALS" && (
                            <InitialsInput
                              id={id}
                              pos={pos}
                              size={size}
                              initials={value}
                              attributes={attributes}
                            />
                          )}
                          {type === "DATE" && (
                            <TextInput
                              type="date"
                              id={id}
                              pos={pos}
                              size={size}
                              attributes={attributes}
                            />
                          )}
                          {type === "CHECKBOX" && (
                            <CheckBoxInput
                              id={id}
                              pos={pos}
                              size={size}
                              attributes={attributes}
                            />
                          )}
                          {type === "RADIO_GROUP" && (
                            <RadioGroupInput
                              id={id}
                              pos={pos}
                              size={size}
                              attributes={attributes}
                            />
                          )}
                        </div>
                      ))}
                  </div>
                ))}
              </div>
            </>
          </React.Fragment>
        )}
      </Formik>
    </SignatureFocusContext.Provider>
  );
};

export default PrintSignedDocumentScreen;

PrintSignedDocumentScreen.propTypes = {
  layout: PropTypes.arrayOf(PropTypes.object).isRequired,
  templateInfo: PropTypes.object.isRequired,
};
