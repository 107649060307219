import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addFormItem } from "@redux/layout";
import { selectTemplateInfo } from "@redux/template-info";
import { v4 as uuidv4 } from "uuid";
import { elementDefaults } from "@components/form-elements";

export default function useFormControlDrag(dropZone) {
  const [selectedControl, setSelectedControl] = React.useState("");
  const dispatch = useDispatch();
  const [active, setActive] = React.useState(false);
  const [started, setStarted] = React.useState(false);
  const templateInfo = useSelector(selectTemplateInfo);

  function onDragStart(event, taskName) {
    // event.preventDefault();
    setStarted(true);
    event.dataTransfer.effectAllowed = "copy";
    // NOTE: this does not work in chrome, can only set dataTransfer on drag end
    setSelectedControl(taskName);
    event.dataTransfer.setData("taskName", taskName);
  }
  function onDragOver(event) {
    setActive(true);
    event.preventDefault();
  }

  function onDragLeave() {
    setActive(false);
  }
  function onDragEnd(event) {
    /* NOTE: handle super fringe bug I experenced on linux/wayland display protocol & chrome */
    // https://bugs.chromium.org/p/chromium/issues/detail?id=875164
    // don't add onDrop event to div manually call it
    setStarted(false);
    setActive(false);
    const clientPos = dropZone.current.getBoundingClientRect();
    const pos = { x: event.clientX, y: event.clientY };
    if (
      pos.x > clientPos.x &&
      pos.x < clientPos.x + clientPos.width &&
      pos.y > clientPos.y &&
      pos.y < clientPos.y + clientPos.height
    ) {
      onDrop(event);
    }
    setSelectedControl("");
  }

  function getRelativeCoords(event) {
    const bounds = dropZone.current.getBoundingClientRect();
    const OFFSETS = { x: 80, y: 50 };
    const x = event.clientX - bounds.left - OFFSETS.x;
    const y = event.clientY - bounds.top - OFFSETS.y;
    return { x: Math.floor(x), y: Math.floor(y) };
  }
  const onDrop = event => {
    setActive(false);
    setStarted(false);
    const pos = getRelativeCoords(event);
    if (selectedControl) {
      dispatch(
        addFormItem({
          id: uuidv4(),
          type: selectedControl,
          page: templateInfo.currentPage ?? 0,
          pos,
          ...elementDefaults[selectedControl]
        })
      );
    }
  };

  return {
    onDragStart,
    onDragOver,
    onDragEnd,
    onDrop,
    active,
    onDragLeave,
    started,
    selectedControl
  };
}
