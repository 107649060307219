import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { assoc, assocPath, prop, values, dissoc, indexBy } from "ramda";

export const layoutItems = createSlice({
  name: "layout",
  initialState: {},
  reducers: {
    clearLayout: () => ({}),
    moveFormItem: (state, { payload }) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      // state.value += 1;
      // state[payload.id].pos = payload.pos;
      return assocPath([payload.id, "pos"], payload.pos, state);
    },
    updateFormItemAttributes: (state, { payload }) => {
      return assocPath([payload.id, "attributes"], payload.attributes, state);
    },
    resizeFormItem: (state, { payload }) => {
      return assocPath([payload.id, "size"], payload.size, state);
    },
    setLayout: (state, { payload }) => indexBy(prop("id"), payload),
    addFormItem: (state, { payload }) => assoc(payload.id, payload, state),
    updateFormItem: (state, { payload }) => assoc(payload.id, payload, state),
    deleteFormItem: (state, { payload }) => dissoc(payload.id, state)
  }
});

export const {
  addFormItem,
  moveFormItem,
  deleteFormItem,
  updateFormItem,
  resizeFormItem,
  updateFormItemAttributes,
  clearLayout,
  setLayout
} = layoutItems.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
// export const selectLayout = state => state.layout;
export const selectLayout = createSelector(prop("layout"), values);
export const getLayoutItem = createSelector(
  [prop("layout"), (_, id) => id],
  (layout, id) => prop(id, layout)
);

export default layoutItems.reducer;
