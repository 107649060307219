import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Draggable from "react-draggable";
import { connect } from "react-redux";
import { moveFormItem } from "../redux/layout";
import {
  TextElement,
  LabelElement,
  SignatureElement,
  InitialsElement,
  CheckBoxElement,
  RadioGroupElement,
} from "./form-elements";

class FormItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      deltaPosition: {
        x: 0,
        y: 0,
      },
      controlledPosition: {
        x: props.pos.x,
        y: props.pos.y,
      },
    };
  }
  state = {};

  handleDrag = (e, ui) => {
    const { x, y } = this.state.deltaPosition;
    this.setState({
      deltaPosition: {
        x: x + ui.deltaX,
        y: y + ui.deltaY,
      },
    });
  };

  onStart = () => {};

  onStop = (e, ui) => {
    const { dispatch, id } = this.props;
    dispatch(
      moveFormItem({ id, pos: { x: Math.round(ui.x), y: Math.round(ui.y) } })
    );
  };

  onControlledDrag = (e, position) => {
    const { x, y } = position;
    this.setState({ controlledPosition: { x, y } });
  };

  onControlledDragStop = (e, position) => {
    this.onControlledDrag(e, position);
    this.onStop();
  };
  render() {
    const dragHandlers = { onStart: this.onStart, onStop: this.onStop };
    const { controlledPosition } = this.state;
    const { type, pos, id, index, attributes, size } = this.props;
    return (
      <Draggable
        position={controlledPosition}
        onDrag={this.onControlledDrag}
        {...dragHandlers}
        bounds="#formbuilder-document"
        handle=".handle"
      >
        <div style={{ position: "absolute" }}>
          {type === "TEXT" && (
            <TextElement
              id={id}
              pos={pos}
              tabIndex={index}
              size={size}
              attributes={attributes}
            />
          )}
          {type === "LABEL" && (
            <LabelElement
              tabIndex={index}
              id={id}
              size={size}
              pos={pos}
              attributes={attributes}
            />
          )}
          {(type === "SIGNATURE" || type === "COACH_SIGNATURE") && (
            <SignatureElement
              tabIndex={index}
              id={id}
              pos={pos}
              size={size}
              attributes={attributes}
            />
          )}
          {type === "INITIALS" && (
            <InitialsElement
              tabIndex={index}
              id={id}
              pos={pos}
              size={size}
              attributes={attributes}
            />
          )}
          {type === "DATE" && (
            <TextElement
              type="date"
              tabIndex={index}
              id={id}
              pos={pos}
              size={size}
              attributes={attributes}
            />
          )}
          {type === "CHECKBOX" && (
            <CheckBoxElement
              tabIndex={index}
              id={id}
              pos={pos}
              size={size}
              attributes={attributes}
            />
          )}
          {type === "RADIO_GROUP" && (
            <RadioGroupElement
              tabIndex={index}
              id={id}
              pos={pos}
              size={size}
              attributes={attributes}
            />
          )}
        </div>
      </Draggable>
    );
  }
}

FormItem.propTypes = {
  pos: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  index: PropTypes.number,
};

export default connect(() => ({}))(FormItem);
