import React from "react";
import PropTypes from "prop-types";
import "./label.scss";

const Label = ({ id, pos, attributes }) => {
  return (
    <div
      className="preview-item label-element"
      style={{ fontSize: `${attributes.fontSize}px`, color: attributes.color }}
    >
      <div className="label-input">{attributes.value}</div>
    </div>
  );
};

Label.propTypes = {
  id: PropTypes.string.isRequired,
};

export default Label;
