import React from "react";
import PropTypes from "prop-types";
import { useFieldFocus } from "../../hooks/signature-focus-context";
import { useFormikContext } from "formik";
import { scrollToElement } from "../../utils";
import "./text-input.scss";

const TextInput = ({ type, id, pos, size, attributes, index, printView }) => {
  const { focusElement, isFocused } = useFieldFocus({
    id,
    attributes
  });
  const { values, handleChange, handleBlur } = useFormikContext();
  const elemRef = React.useRef();
  React.useEffect(() => {
    if (isFocused && elemRef && elemRef.current) {
      scrollToElement(elemRef.current);
    }
  }, [isFocused]);
  return (
    <div
      className={`preview-item text-element ${isFocused ? "focused" : ""}`}
      style={{
        width: size.absoluteWidth,
        height: size.absoluteHeight,
        margin: `${size.absoluteTop} 0 0 ${size.absoluteLeft}`
      }}
      onClick={() => {
        focusElement(id);
      }}
    >
      {!printView ? (
        <input
          type={type}
          className={`input default ${attributes.underline ? "underline" : ""}`}
          ref={elemRef}
          onBlur={handleBlur}
          placeholder={attributes.placeHolder}
          onFocus={e => {
            focusElement(id);
          }}
          name={id}
          id={id}
          style={{
            width: "100%",
            height: "100%",
            paddingLeft: 2,
            paddingRight: 2,
            background: attributes.background
          }}
          value={values[id]}
          onChange={handleChange}
        />
      ) : (
        <div
          id={id}
          className={`input default ${attributes.underline ? "underline" : ""}`}
          style={{
            width: "100%",
            height: "100%",
            background: attributes.background,
            paddingLeft: 2,
            paddingRight: 2
          }}
        >
          {values[id]}
        </div>
      )}
    </div>
  );
};

TextInput.propTypes = {
  id: PropTypes.string.isRequired,
  size: PropTypes.object.isRequired,
  printView: PropTypes.bool
};

TextInput.defaultProps = {
  type: "text"
};

export default TextInput;
