import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { Formik } from "formik";
import ErrorPage from "@components/error-view";
import * as yup from "yup";
import { Button, Form } from "semantic-ui-react";
import Loading from "@components/ui/loading";
import { useMutation } from "react-query";
import useTokenValidation from "@hooks/useTokenValidation";

const CopyDocumentSchema = yup.object().shape({
  title: yup.string().required(),
});

const CopySignatureTemplate = () => {
  const location = useLocation();
  const params = queryString.parse(location.search);
  const [error, setError] = useState(false);
  useTokenValidation(params.token);

  const copySignatureTemplateApi = useMutation(async ({ values, params }) => {
    const payload = {
      title: values.title,
      description: values.description,
      template_id: params.template_id,
    };

    const response = await fetch("/api/template/copy-template", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${params.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    if (response.ok) {
      const templateInfo = await response.json();
      if (templateInfo.error) setError(templateInfo.message);
      else setError(false);
      return templateInfo;
    }
    var error = await response.text();
    throw error;
  });

  return (
    <div
      style={{
        padding: 5,
        flex: 1,
        display: "flex",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {copySignatureTemplateApi.isLoading && <Loading />}
      {copySignatureTemplateApi.isError ? (
        <ErrorPage errorMessage={copySignatureTemplateApi.error} />
      ) : !error && copySignatureTemplateApi.isSuccess ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "calc(100vh - 80px)",
            padding: "0 15px",
          }}
        >
          <h1
            style={{
              minHeight: "1rem",
              fontSize: "2rem",
              lineHeight: "1.28571429em",
              margin: "calc(2rem - .14286em) 0 1rem",
              fontWeight: "700",
              marginBottom: 50,
            }}
          >
            Thank you, your document has submitted successfully.
          </h1>
          <p
            style={{
              textAlign: "center",
              margin: "0 0 1em",
              lineHeight: "1.4285em",
              marginBottom: 10,
            }}
          >
            Please close this window.
          </p>
          <button
            className="button primary"
            onClick={() => {
              window.close();
            }}
          >
            Close
          </button>
        </div>
      ) : (
        <Formik
          initialValues={{ description: "", title: "" }}
          onSubmit={async (values) => {
            await copySignatureTemplateApi.mutate({
              values,
              params,
            });
          }}
          validationSchema={CopyDocumentSchema}
        >
          {({ isSubmitting, handleChange, values, handleSubmit }) => (
            <Form
              style={{
                minWidth: 400,
                border: "1px solid #ccc",
                borderRadius: 5,
                background: "#f5f5f5",
                padding: 10,
              }}
              onSubmit={handleSubmit}
            >
              <h1
                style={{
                  textAlign: "center",
                  fontFamily:
                    'Lato,"Helvetica Neue",Arial,Helvetica,sans-serif',
                }}
              >
                Copy Signature Template
              </h1>
              <Form.Field>
                <label>Title</label>
                <input
                  type="text"
                  placeholder="Template Title"
                  value={values.title}
                  onChange={handleChange("title")}
                  required
                />
              </Form.Field>
              <label style={{ color: "red", padding: 5 }}>{error}</label>

              <Form.Field style={{ marginTop: 20 }}>
                <label>Description</label>
                <input
                  type="text"
                  placeholder="Template Description"
                  value={values.description}
                  onChange={handleChange("description")}
                  required
                />
              </Form.Field>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "flex-start",
                  marginTop: 20,
                }}
              >
                {isSubmitting && <Loading />}
                <Button type="submit" primary>
                  Copy
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default CopySignatureTemplate;
