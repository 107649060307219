import { createSlice } from "@reduxjs/toolkit";
import { prop } from "ramda";

export const templateInfo = createSlice({
  name: "template_info",
  initialState: {
    loaded: false,
    template_id: "",
    token: "",
    title: "",
    description: "",
    fileName: "",
    pages: 1,
    files: [],
    currentPage: 0
  },
  reducers: {
    setTemplateInfo: (state, { payload }) => ({ ...state, ...payload }),
    setCurrentPage: (state, { payload }) => ({ ...state, currentPage: payload })
  }
});

export const { setTemplateInfo, setCurrentPage } = templateInfo.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
// export const selectLayout = state => state.layout;
export const selectTemplateInfo = prop("template_info"); //createSelector(prop("document"), values);

export default templateInfo.reducer;
