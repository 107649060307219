import React from "react";
import PropTypes from "prop-types";

const ErrorPage = ({ errorMessage, goBack }) => {
  return (
    <div
      className="error-page"
      style={{
        height: "100vh",
        width: "100vw",
        position: "fixed",
        top: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 10
      }}
    >
      <h3 style={{ padding: 10 }}>Woops Sorry, something went wrong</h3>
      <div style={{ color: "red", padding: 5 }}>{errorMessage}</div>
      <div>{goBack && <button onClick={() => {}}>Go Back</button>}</div>
    </div>
  );
};

ErrorPage.propTypes = {
  errorMessage: PropTypes.string,
  goBack: PropTypes.bool
};

export default ErrorPage;
