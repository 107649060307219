import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { deleteFormItem, updateFormItemAttributes } from "../../redux/layout";
import Field from "../ui/field";
import Button from "../ui/button";
import Input from "../ui/input";
import Controls from "./controls";
import useHover from "../../hooks/use-hover";
import useResize from "../../hooks/use-resize";
import { Resizable } from "react-resizable";

const RadioGroupElement = ({ id, pos, size: origSize, attributes }) => {
  const dispatch = useDispatch();
  const [elemRef, isHovered] = useHover();
  const { size, onResize, onResizeStop } = useResize(id, origSize);
  return (
    <div className="layout-item radio-group-element" ref={elemRef}>
      <Controls
        handleDelete={() => {
          dispatch(deleteFormItem({ id }));
        }}
        showControls={isHovered}
        EditComponent={
          <div
            className="edit-element-container"
            style={{ display: "flex", flexDirection: "column" }}
          >
            {attributes.elms.map(({ text, value }, index) => (
              <div style={{ display: "flex", flex: 1 }} key={value}>
                <Field label="Label">
                  <input
                    defaultValue={text}
                    required
                    style={{ display: "flex-inline", flex: 1 }}
                    onBlur={({ target }) => {
                      const { elms } = attributes;
                      const updatedElms = elms.map((el, elIndex) =>
                        elIndex === index ? { ...el, text: target.value } : el
                      );
                      dispatch(
                        updateFormItemAttributes({
                          id,
                          attributes: {
                            ...attributes,
                            elms: updatedElms
                          }
                        })
                      );
                    }}
                    type="text"
                  />
                </Field>
                <Field label="Value">
                  <input
                    required
                    style={{ display: "flex-inline", flex: 1 }}
                    defaultValue={value}
                    onBlur={({ target }) => {
                      const { elms } = attributes;
                      const updatedElms = elms.map((el, elIndex) =>
                        elIndex === index ? { ...el, value: target.value } : el
                      );
                      dispatch(
                        updateFormItemAttributes({
                          id,
                          attributes: {
                            ...attributes,
                            elms: updatedElms
                          }
                        })
                      );
                    }}
                    type="text"
                  />
                </Field>
                <Button
                  onClick={() => {
                    const elms = attributes.elms.filter(
                      (el, elIndex) => elIndex !== index
                    );
                    dispatch(
                      updateFormItemAttributes({
                        id,
                        attributes: {
                          ...attributes,
                          elms
                        }
                      })
                    );
                  }}
                  className="danger small"
                  style={{
                    padding: "0.48571429em 1em 0.48571429em"
                  }}
                >
                  Delete
                </Button>
              </div>
            ))}
            <button
              onClick={() => {
                const elms = [
                  ...attributes.elms,
                  {
                    text: `option${attributes.elms.length + 1}`,
                    value: `option${attributes.elms.length + 1}`
                  }
                ];
                dispatch(
                  updateFormItemAttributes({
                    id,
                    attributes: {
                      ...attributes,
                      elms
                    }
                  })
                );
              }}
            >
              Add
            </button>
            <Field label="Required">
              <Input
                checked={attributes.required}
                type="checkbox"
                onChange={({ target }) =>
                  dispatch(
                    updateFormItemAttributes({
                      id,
                      attributes: {
                        ...attributes,
                        required: target.checked
                      }
                    })
                  )
                }
              />
            </Field>
          </div>
        }
      >
        <Resizable
          className="absolutely-positioned"
          height={size.absoluteHeight}
          width={size.absoluteWidth}
          onResize={onResize}
          onResizeStop={onResizeStop}
          resizeHandles={["se"]}
        >
          <div
            style={{
              width: size.absoluteWidth,
              height: size.absoluteHeight,
              margin: `${size.absoluteTop} 0 0 ${size.absoluteLeft}`
            }}
          >
            <div className="radio-group handle">
              {attributes.elms.map(({ value, text }) => (
                <div key={value} className="radio-group-item">
                  <input type="radio" name={value} value={value} readOnly />
                  <label className="radio-item-label" htmlFor={value}>
                    {text}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </Resizable>
      </Controls>
    </div>
  );
};

RadioGroupElement.propTypes = {
  id: PropTypes.string.isRequired
};

export default RadioGroupElement;
