import { configureStore } from "@reduxjs/toolkit";
import layoutReducer from "./layout";
import templateInfoReducer from "./template-info";

export default configureStore({
  reducer: {
    layout: layoutReducer,
    template_info: templateInfoReducer
  }
});
