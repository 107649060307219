import React from "react";
import PropTypes from "prop-types";
import "./button.scss";

const Button = ({ onClick, children, className, ...rest }) => {
  return (
    <button onClick={onClick} className={`button ${className}`} {...rest}>
      {children}
    </button>
  );
};

Button.defaultProps = {};

Button.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string
};

export default Button;
