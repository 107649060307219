import * as R from "ramda";

export const getInitials = s => {
  if (s === undefined) {
    return "";
  } else {
    const names = s.split(" ");
    let initials = names[0].substring(0, 1).toUpperCase();
    if (names.length > 2) {
      initials += names[names.length - 2].substring(0, 1).toUpperCase();
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    } else if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }
};

// janky sort, idea was to give abituary weights primary left to right and don't look at specific pixes but sections of pages
// so it follows more of a natural reading pattern
export const sortByPosition = items => {
  return R.sortWith([
    R.ascend(({ pos }) => Math.floor(pos.y / 10)),
    R.ascend(({ pos }) => Math.floor(pos.x))
  ])(items);
};

export const getNextError = (formElements, errors) =>
  R.find(({ id }) => {
    return !!R.prop(id, errors);
  })(formElements);

export const scrollToElement = element => {
  var container = document.getElementById("form-builder-preview");
  const OFFSET_TOP = 100;
  const OFFSET_LEFT = 50;
  var rec = element.getBoundingClientRect();
  const scrollToTop = container.scrollTop + rec.top - OFFSET_TOP;
  const scrollToLeft = container.scrollLeft + rec.left - OFFSET_LEFT;
  element.focus();
  container.scrollTo({
    top: scrollToTop,
    left: scrollToLeft,
    behavior: "smooth"
  });
};
