import React, { useState, useEffect } from "react";
import PrintSignedDocumentScreen from "./print-view";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useQuery } from "react-query";
import Loading from "../ui/loading";
import "./style.scss";

const PrintDocumentView = () => {
  const location = useLocation();
  const params = queryString.parse(location.search);
  const [layout, setLayout] = useState(null);
  const [initialValues, setInitialValues] = useState(null);

  const templateInfoApi = useQuery(["meta", params], async () => {
    const response = await fetch(
      `/api/template/download/${params.template_id}/meta.json?token=${params.token}`
    );
    const data = await response.json();
    return { ...params, ...data };
  });

  const signedLayoutApi = async () => {
    const response = await fetch(
      `/api/template/download/${params.template_id}/${params.signature_id}_signed.json?token=${params.token}`
    );
    const result = await response.json();
    return result;
  };

  const coachSignedLayoutApi = async () => {
    const response = await fetch(
      `/api/template/download/${params.template_id}/${params.signature_id}_coach_signed.json?token=${params.token}`
    );
    const result = await response.json();
    return result;
  };

  const templateSignedByCoachApi = useQuery(["signed_by_coach", params], async () => {
    const response = await fetch(`/api/signature/${params.signature_id}/coach-signed`, {
      method: "GET"
    });
    const result = await response.json();
    return result;
  });

  useEffect(() => {

      async function fetchData() {
          if (!templateSignedByCoachApi.isLoading && !templateSignedByCoachApi.isError && layout == null) {
              const isSignedByCoach = templateSignedByCoachApi.data;
              let lay = [];
              if (isSignedByCoach && params.coach == true) {
                  lay = await coachSignedLayoutApi();
              }
              else {
                  lay = await signedLayoutApi();
              }
              setLayout(lay);
              const initValues = lay.reduce((prev, cur) => {
                  return { [cur.id]: cur.value, ...prev };
              }, {}) ?? {};
              setInitialValues(initValues);
          }
      }

      fetchData();
  }, [templateSignedByCoachApi])

  return templateInfoApi.isLoading || templateSignedByCoachApi.isLoading || layout == null || initialValues == null ? (
    <div>
      <Loading />
    </div>
  ) : (
    <PrintSignedDocumentScreen
      layout={layout}
      templateInfo={templateInfoApi.data ?? {}}
      initialValues={initialValues}
    />
  );
};

export default PrintDocumentView;
