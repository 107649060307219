import { useEffect, useState } from "react";
import { browserHistory } from "../index";

export const validateUserToken = async (token) => {
  const response = await fetch(`/api/auth`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ token }),
  });
  if (!response.ok) throw response.text();
  const result = await response.json();
  return result;
};

const useTokenValidation = (token, decodeToken = false) => {
  const [isAuthorized, setIsAuthorized] = useState();
  async function checkToken() {
    try {
      const isValid = await validateUserToken(
        decodeToken ? window.btoa(token) : token
      );
      if (!isValid) browserHistory.push("/not-authorized");
      setIsAuthorized(isValid);
    } catch (e) {
      /* handle error */
      browserHistory.push("/not-authorized");
    }
  }
  useEffect(() => {
    checkToken();
  }, [token]); // eslint-disable-line
  return isAuthorized;
};

export default useTokenValidation;
